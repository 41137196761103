.businessRegisterPageContainer {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 750px;
  width: 100%;
  height: 600px;
  border-radius: 15px;
  overflow: hidden;
}

.businessRegisterPageLeftDiv {
  position: relative;
  background-color: #b90504;
  width: 300px;
  height: 100%;
  color: white;
  font-family: Montserrat;
  text-align: center;
  padding-bottom: 20px;
  box-sizing: border-box;
}

.businessRegisterPageLeftDiv h1 {
  font-weight: 500;
  margin-top: 120px;
}

.businessRegisterPageLeftDiv h2 {
  margin-top: 50px;
  font-weight: 400;
  font-size: 17px;
}

.businessRegisterPageLeftDiv div {
  margin-top: auto;
}

.businessRegisterPageLeftDiv p {
  font-size: 15px;
}

.businessRegisterPageLeftDiv button {
  height: 30px;
  background: transparent;
  border: 2px solid white;
  font-family: Roboto;
  color: white;
  font-size: 16px;
  border-radius: 15px;
  width: fit-content;
  padding: 0px 12px;
  cursor: pointer;
  margin-top: 10px;
  transition: all 200ms ease-in;
}

.businessRegisterPageLeftDiv button:hover {
  background: white;
  color: #b90504;
}

.businessRegisterPageLeftDiv img {
  position: absolute;
  top: 15px;
  left: 20px;
  width: 50px;
  height: 50px;
}

.businessRegisterPageRightDiv {
  background-color: #333333;
  width: calc(100% - 300px);
  height: 100%;
}

.businessRegisterPageTabsContainer {
  width: 100%;
  height: 80px;
  color: white;
  border-bottom: 4px solid #403d3d;
  font-family: Montserrat;
  gap: 10px;
}

.businessRegisterPageTabs {
  width: 80px;
  height: 80px;
  color: dimgray;
}

.businessRegisterPageTabs.completed {
  color: white;
}

.businessRegisterPageTabs.completed svg {
  fill: white;
}

.businessRegisterPageTabs p {
  font-size: 13px;
  font-weight: 500;
}

.businessRegisterPageTabs svg {
  fill: dimgray;
  width: 40px;
  height: 40px;
}

.businessRegisterPageForm {
  height: calc(100% - 84px);
  width: 100%;
  position: relative;
  gap: 15px;
  padding: 30px 0px 60px 0px;
  box-sizing: border-box;
}

.businessRegisterPageForm p {
  color: white;
  font-family: Montserrat;
  width: calc(70% + 30px);
  font-size: 13px;
}

.businessRegisterPageForm button[type="submit"],
.businessRegisterPageMapForm button[type="submit"] {
  position: absolute;
  bottom: 15px;
  overflow: hidden;
}

.businessRegisterPageMapForm {
  height: calc(100% - 84px);
  width: 100%;
  position: relative;
  gap: 15px;
}

.businessRegisterPageMapDiv {
  width: 100%;
  position: relative;
  box-sizing: border-box;
}

.businessRegisterPageMapDiv div:nth-child(1) {
  aspect-ratio: 16/9;
  height: unset !important;
}

.businessRegisterPageSetLocation {
  position: absolute;
  left: 50%;
  bottom: 5px;
  transform: translate(-50%, 0);
}

.businessRegisterPageDisabledInput {
  width: 70%;
  height: 40px;
  border-radius: 10px;
  padding: 0px 15px;
  font-family: Montserrat;
  font-weight: 600;
  font-size: 22px;
  color: white;
  border: 2px solid white;
}

@media (max-width: 750px) {
  .businessRegisterPageContainer {
    flex-direction: column-reverse;
    min-height: 100%;
    height: auto;
    border-radius: 0;
    position: relative;
    top: 0;
    left: 0;
    transform: unset;
  }

  .businessRegisterPageRightDiv {
    position: relative;
    min-height: calc(100vh - 140px);
    height: unset;
    width: 100%;
  }

  .businessRegisterPageLeftDiv {
    height: 140px;
    width: 100%;
    padding: 0;
  }

  .businessRegisterPageForm {
    position: unset;
    height: 100%;
  }

  .businessRegisterPageMapForm {
    padding-bottom: 70px;
    position: unset;
  }

  .businessRegisterPageForm button[type="submit"],
  .businessRegisterPageMapForm button[type="submit"] {
    position: absolute;
  }

  .businessRegisterPageLeftDiv h1,
  .businessRegisterPageLeftDiv h2,
  .businessRegisterPageLeftDiv img {
    display: none;
  }

  .businessRegisterPageLeftDiv div {
    margin: auto;
  }
}

@media (max-width: 480px) {
  .businessRegisterPageMapDiv div:nth-child(1) {
    aspect-ratio: 16/11;
  }
}
