.businessInventoryMainContainer {
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  margin: 20px 0px;
  max-width: 1400px;
  padding: 0px 10px;
  box-sizing: border-box;
}

.businessInventoryHeaderDiv {
  padding: 0px 10px;
  box-sizing: border-box;
  width: 100%;
  margin-bottom: 7px;
}

#inventoryToolsDiv {
  position: relative;
  width: 100%;
  height: 80px;
  padding: 0px 15px;
  box-sizing: border-box;
  background-color: white;
  border-radius: 20px;
}

#addItemsButton {
  margin-left: auto;
  border: none;
  background-color: #b90504;
  height: 40px;
  padding: 0px 20px;
  color: white;
  font-family: Montserrat;
  font-weight: bold;
  border-radius: 20px;
  cursor: pointer;
}

#addItemsButton:active {
  background-color: #990100;
}

#inventoryToolsDiv input {
  height: 40px;
  padding-left: 20px;
  border-radius: 20px;
  border: none;
  background-color: lightgray;
  font-family: Montserrat;
  font-weight: 600;
  width: 250px;
}

#inventoryHeader {
  font-size: 25px;
  color: dimgray;
  font-family: Montserrat;
  font-weight: 500;
}

#howToUseInventory {
  margin-left: auto;
  color: dimgray;
  font-family: Montserrat;
  cursor: pointer;
}

#howToUseInventory h2 {
  font-weight: 500;
  font-size: 18px;
}

#inventorySearchButton:active {
  background-color: #990100;
}

#inventoryListDiv {
  margin: 10px 0;
  width: 100%;
  background-color: white;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  padding-bottom: 20px;
}

#inventoryColumnLabelDiv {
  width: 100%;
  height: 60px;
  border-bottom: 3px solid #e8e8e8;
  display: grid;
  grid-template-columns: 3fr 1fr 2fr 1fr;
  justify-content: center;
  align-items: center;
}

#inventoryColumnLabelDiv h1 {
  font-family: Montserrat;
  font-weight: 600;
  color: dimgray;
  font-size: 25px;
  text-align: center;
}

.inventoryCard {
  display: grid;
  grid-template-columns: 3fr 1fr 2fr 1fr;
  width: 100%;
  height: 150px;
  border-bottom: 2px solid #e8e8e8;
  align-items: center;
  font-family: Montserrat;
}

.businessInventoryProductImageAndTextDiv {
  padding: 0px 15px;
  gap: 20px;
  height: 150px;
  box-sizing: border-box;
}

.businessInventoryProductImageDiv {
  min-width: 120px;
  max-width: 120px;
  padding: 15px 0px;
  box-sizing: border-box;
  height: 150px;
}

.businessInventoryProductImageDiv img {
  max-height: 100%;
  max-width: 100%;
}

.businessInventoryProductTextDiv {
  padding: 5px 0;
  height: 100%;
  box-sizing: border-box;
  gap: 5px;
}

.businessInventoryProductTextDiv h2 {
  font-size: 16px;
  font-weight: 600;
}

.inventoryPriceColumnDiv h2 {
  text-align: center;
  font-weight: 500;
}

.inventoryAvailabilityColumnDiv {
  display: flex;
  justify-content: center;
  align-items: center;
}

.inventoryOptionColumnDiv {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 3px;
}

#submitAndUpdateEditPageDiv {
  position: absolute;
  bottom: 10px;
  display: flex;
  gap: 10px;
}

.businessInventoryMobileAvailabilityTag {
  height: 30px;
  background-color: #b90504;
  color: white;
  border-radius: 0 0 0 10px;
  font-weight: 600;
  font-size: 20px;
}

@media (max-width: 780px) {
  .inventoryCard {
    grid-template-columns: 3fr 1.2fr 1fr;
  }

  #inventoryColumnLabelDiv {
    grid-template-columns: 3fr 1.2fr 1fr;
  }

  .businessInventoryProductImageAndTextDiv {
    padding: 10px 15px;
  }

  .businessInventoryProductTextDiv span {
    color: #b90504;
  }

  .businessInventoryProductTextDiv h2 {
    font-size: 16px;
  }

  .businessInventoryProductTextDiv h3 {
    font-weight: 600;
    margin-top: auto;
  }
}

@media (max-width: 480px) {
  .businessInventoryMainContainer {
    margin: 10px 0 0 0;
  }

  .businessInventoryHeaderDiv {
    padding: 0;
  }

  #inventoryToolsDiv {
    height: 55px;
    border-radius: 10px;
    padding: 7px;
  }

  #inventoryToolsDiv input {
    width: 100%;
    border-radius: 7px;
  }

  #inventoryListDiv {
    background-color: transparent;
    gap: 10px;
  }

  .inventoryCard {
    position: relative;
    background-color: white;
    border-radius: 10px;
    display: flex;
    height: 130px;
  }

  .businessInventoryProductImageAndTextDiv {
    padding: 0px 5px 0 0;
    gap: 10px;
    height: 130px;
  }

  .businessInventoryProductImageDiv {
    padding: 0;
    height: 100px;
    min-width: 100px;
    max-width: 100px;
  }

  .businessInventoryProductImageDiv img {
    border-radius: 10px 0 0 0;
  }

  .businessInventoryProductTextDiv span {
    color: #b90504;
  }

  .businessInventoryProductTextDiv h2 {
    font-size: 14px;
  }

  .businessInventoryProductTextDiv h3 {
    font-size: 16px;
    font-weight: 600;
    margin-top: auto;
  }

  .businessInventoryProductTextDiv .groceryTag {
    height: 22px;
    font-size: 12px;
    padding: 0px 5px;
    margin: 2px 0;
  }

  .inventoryOptionColumnDiv .redButton {
    position: absolute;
    bottom: 5px;
    right: 10px;
    height: 30px;
    border-radius: 5px;
    padding: 0px 10px;
  }
}
