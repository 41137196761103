#ordersPageMainContainer {
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  max-width: 1400px;
  margin: 20px 0px;
  padding: 0px 20px;
  box-sizing: border-box;
  justify-content: start;
}

#orderTabsContainer {
  background-color: white;
  height: 60px;
  width: 100%;
  border-radius: 15px;
  display: flex;
  justify-content: left;
  align-items: center;
}

.orderTabs {
  color: dimgray;
  padding: 0px 20px;
  font-family: Roboto;
  cursor: pointer;
}

.orderTabsActive {
  background-color: #b90504;
  height: 40px;
  color: white;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.orderTabs h2 {
  display: flex;
  gap: 7px;
  align-items: center;
  font-size: 24px;
}

.orderTabs span {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #e8e8e8;
  width: 25px;
  height: 25px;
  border-radius: 15px;
  font-size: 15px;
}

.orderTabs.orderTabsActive span {
  background-color: white;
  color: #b90504;
}

.ordersContainer {
  flex-wrap: wrap;
  margin: 20px 0 0 0;
  gap: 10px;
  width: 100%;
}

.orderCard {
  position: relative;
  background-color: white;
  width: calc(100% / 3 - 7.5px);
  border-radius: 10px;
  padding: 40px 7px 10px 7px;
  box-sizing: border-box;
}

.orderTag {
  position: absolute;
  left: 0px;
  top: 0px;
  border-radius: 10px 0px;
  height: 30px;
  font-family: Montserrat;
  color: white;
  font-weight: 600;
  padding: 0px 10px;
  display: flex;
  align-items: center;
}

.orderTag.Pending {
  background-color: rgb(255, 191, 0);
}

.orderTag.Active {
  background-color: rgb(80, 168, 255);
}

.orderTag.Cancelled {
  background-color: #b90504;
}

.ordersAddressDiv {
  font-family: Montserrat;
  border-radius: 5px;
  background-color: #e8e8e8;
  padding: 5px 10px;
  box-sizing: border-box;
  width: 100%;
  font-size: 14px;
  margin: 0 0 5px 0;
  height: 60px;
}

.ordersAddressDiv span {
  font-weight: 600;
}

.ordersOrderedProductsDiv {
  background-color: #e8e8e8;
  font-family: Montserrat;
  border-radius: 5px;
  padding: 5px 0;
  height: 185px;
  box-sizing: border-box;
  overflow-y: scroll;
  overflow-x: hidden;
}

.ordersOrderedProductsDiv::-webkit-scrollbar {
  width: 6px;
}

.ordersOrderedProductsDiv::-webkit-scrollbar-thumb {
  background-color: #333333;
  border-radius: 3px;
}

.ordersItemDiv {
  gap: 20px;
  padding: 9px 10px;
  box-sizing: border-box;
  border-bottom: 2px solid white;
}

.ordersItemDiv h2 {
  font-size: 12px;
  font-weight: 400;
  width: 75%;
}

.ordersItemDiv h3 {
  font-size: 15px;
  font-weight: 500;
  max-width: 30%;
  min-width: 25%;
  text-align: right;
}

.ordersButtonDiv {
  width: 100%;
  gap: 10px;
  margin-top: 10px;
}

.ordersButton {
  width: 50%;
  height: 40px;
  border-radius: 5px;
  border: none;
  color: white;
  font-weight: bolder;
  font-size: 20px;
  cursor: pointer;
}

.ordersButton:nth-of-type(1) {
  background-color: green;
}

.ordersButton:nth-of-type(2) {
  background-color: #b90504;
}

.ordersButton:nth-of-type(1):hover {
  background-color: darkgreen;
}

.ordersButton:nth-of-type(2):hover {
  background-color: #990100;
}

@media (max-width: 780px) {
  #ordersPageMainContainer {
    padding: 0px 10px;
  }

  .orderCard {
    width: calc(50% - 5px);
  }
}

@media (max-width: 480px) {
  #ordersPageMainContainer {
    padding: 0px 5px;
  }

  .orderCard {
    width: 100%;
  }

  #orderTabsContainer {
    overflow-x: scroll;
    padding: 0 10px;
    box-sizing: border-box;
  }

  .ordersContainer {
    margin: 10px 0 0 0;
  }

  .orderTabs {
    margin: 0 !important;
  }
}
