@import url(https://fonts.googleapis.com/css?family=Spartan:100,200,300,400,500,600,700,800,900&display=swap%27);
@import url(https://fonts.googleapis.com/css?family=Roboto:100,200,300,400,500,600,700,800,900&display=swap%27);
@import url(https://fonts.googleapis.com/css?family=Montserrat:100,200,300,400,500,600,700,800,900&display=swap%27);
@import url(https://fonts.googleapis.com/css?family=Noto+Sans:100,200,300,400,500,600,700,800,900&display=swap%27);
* {
  margin: 0;
  padding: 0%;
}

body {
  background-color: #e8e8e8;
}

#root {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.redButton {
  border: none;
  background-color: #b90504;
  height: 40px;
  padding: 0px 20px;
  color: white;
  font-family: Montserrat;
  font-weight: bold;
  border-radius: 20px;
  cursor: pointer;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

.overlayDiv {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.85);
  z-index: 10;
  -webkit-animation-duration: 500ms;
          animation-duration: 500ms;
}

@-webkit-keyframes overlayFadeIn {
  from {
    background-color: rgba(0, 0, 0, 0);
  }
  to {
    background-color: rgba(0, 0, 0, 0.85);
  }
}

@keyframes overlayFadeIn {
  from {
    background-color: rgba(0, 0, 0, 0);
  }
  to {
    background-color: rgba(0, 0, 0, 0.85);
  }
}

@-webkit-keyframes overlayFadeOut {
  from {
    background-color: rgba(0, 0, 0, 0.85);
  }
  to {
    background-color: rgba(0, 0, 0, 0);
  }
}

@keyframes overlayFadeOut {
  from {
    background-color: rgba(0, 0, 0, 0.85);
  }
  to {
    background-color: rgba(0, 0, 0, 0);
  }
}

.textDecorNone {
  text-decoration: none;
}

.animatedInputDivs {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.animatedInputLabels {
  position: absolute;
  left: 15%;
  font-family: Montserrat;
  font-weight: bold;
  color: dimgray;
  font-size: 20px;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
  pointer-events: none;
  background-color: rgb(34, 34, 34);
  transition: font-size 200ms ease-in,
    padding 200ms ease-in, color 100ms ease-in, -webkit-transform 200ms ease-in;
  transition: transform 200ms ease-in, font-size 200ms ease-in,
    padding 200ms ease-in, color 100ms ease-in;
  transition: transform 200ms ease-in, font-size 200ms ease-in,
    padding 200ms ease-in, color 100ms ease-in, -webkit-transform 200ms ease-in;
}

.animatedInputInputs {
  width: 70%;
  height: 40px;
  border-radius: 10px;
  font-family: Montserrat;
  font-weight: bold;
  font-size: 20px;
  padding: 0px 15px;
  background-color: transparent;
  border: 2px solid white;
  color: white;
}

.animatedInputInputs:focus {
  outline: none;
  border: 2px solid #b90504;
}

/* main labels */
.animatedInputInputs:not(:-ms-input-placeholder) ~ .animatedInputLabels {
  transform: translate(-4px, -20px);
  font-size: 12px;
  padding: 0px 3px;
  color: #b90504;
}
.animatedInputInputs:focus ~ .animatedInputLabels,
.animatedInputInputs:not(:placeholder-shown) ~ .animatedInputLabels {
  -webkit-transform: translate(-4px, -20px);
          transform: translate(-4px, -20px);
  font-size: 12px;
  padding: 0px 3px;
  color: #b90504;
}

.animatedInputInputs:not(:-ms-input-placeholder).animatedInputInputs:not(:focus)
  ~ .animatedInputLabels {
  color: white;
}

.animatedInputInputs:not(:placeholder-shown).animatedInputInputs:not(:focus)
  ~ .animatedInputLabels {
  color: white;
}

/* lighter gray labels */
.animatedInputLabelsLighterGray {
  position: absolute;
  left: 15%;
  font-family: Montserrat;
  font-weight: bold;
  color: dimgray;
  font-size: 20px;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
  pointer-events: none;
  background-color: #333333;
  transition: font-size 200ms ease-in,
    padding 200ms ease-in, color 100ms ease-in, -webkit-transform 200ms ease-in;
  transition: transform 200ms ease-in, font-size 200ms ease-in,
    padding 200ms ease-in, color 100ms ease-in;
  transition: transform 200ms ease-in, font-size 200ms ease-in,
    padding 200ms ease-in, color 100ms ease-in, -webkit-transform 200ms ease-in;
}

.animatedInputInputs:not(:-ms-input-placeholder) ~ .animatedInputLabelsLighterGray {
  transform: translate(-4px, -20px);
  font-size: 12px;
  padding: 0px 3px;
  color: #b90504;
}

.animatedInputInputs:focus ~ .animatedInputLabelsLighterGray,
.animatedInputInputs:not(:placeholder-shown) ~ .animatedInputLabelsLighterGray {
  -webkit-transform: translate(-4px, -20px);
          transform: translate(-4px, -20px);
  font-size: 12px;
  padding: 0px 3px;
  color: #b90504;
}

.animatedInputInputs:not(:-ms-input-placeholder).animatedInputInputs:not(:focus)
  ~ .animatedInputLabelsLighterGray {
  color: white;
}

.animatedInputInputs:not(:placeholder-shown).animatedInputInputs:not(:focus)
  ~ .animatedInputLabelsLighterGray {
  color: white;
}

/* smaller gray labels */
.animatedInputLabelsSmallerLighterGray {
  position: absolute;
  left: 15%;
  font-family: Montserrat;
  font-weight: bold;
  color: dimgray;
  font-size: 13px;
  background-color: #333333;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
  pointer-events: none;
  transition: font-size 200ms ease-in,
    padding 200ms ease-in, color 100ms ease-in, -webkit-transform 200ms ease-in;
  transition: transform 200ms ease-in, font-size 200ms ease-in,
    padding 200ms ease-in, color 100ms ease-in;
  transition: transform 200ms ease-in, font-size 200ms ease-in,
    padding 200ms ease-in, color 100ms ease-in, -webkit-transform 200ms ease-in;
}

.animatedInputInputs:not(:-ms-input-placeholder)
  ~ .animatedInputLabelsSmallerLighterGray {
  transform: translate(-4px, -20px);
  font-size: 10px;
  padding: 0px 3px;
  color: #b90504;
}

.animatedInputInputs:focus ~ .animatedInputLabelsSmallerLighterGray,
.animatedInputInputs:not(:placeholder-shown)
  ~ .animatedInputLabelsSmallerLighterGray {
  -webkit-transform: translate(-4px, -20px);
          transform: translate(-4px, -20px);
  font-size: 10px;
  padding: 0px 3px;
  color: #b90504;
}

.animatedInputInputs:not(:-ms-input-placeholder).animatedInputInputs:not(:focus)
  ~ .animatedInputLabelsSmallerLighterGray {
  color: white;
}

.animatedInputInputs:not(:placeholder-shown).animatedInputInputs:not(:focus)
  ~ .animatedInputLabelsSmallerLighterGray {
  color: white;
}

/* animated box shadow */
.card-boxshadow {
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
}

.card-boxshadow:hover {
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
}

.text1Line {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  line-clamp: 1;
  -webkit-box-orient: vertical;
}

.text2Lines {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
}

.text3Lines {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  line-clamp: 3;
  -webkit-box-orient: vertical;
}

.text4Lines {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  line-clamp: 4;
  -webkit-box-orient: vertical;
}

/* flexboxes */
.flex {
  display: flex;
}

.flexColumn {
  display: flex;
  flex-direction: column;
}

.flexJustifyAndAlignCenter {
  justify-content: center;
  align-items: center;
}

.flexJustifyCenter {
  justify-content: center;
}

.flexAlignCenter {
  align-items: center;
}

.flexJustifyRight {
  justify-content: right;
}

.flexJustifyLeft {
  justify-content: left;
}

.flexAlignStart {
  align-items: flex-start;
}

.flexAlignEnd {
  align-items: flex-end;
}

.fileInputLabel {
  background-color: #b90504;
  color: white;
  font-family: Montserrat;
  height: 40px;
  border-radius: 20px;
  padding: 0px 20px;
  font-weight: bold;
}

.groceryTag {
  background-color: green;
  margin: 5px 0px;
  height: 25px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  padding: 0px 10px;
  color: white;
  font-family: Montserrat;
  font-weight: bold;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: default;
}

.closeButton {
  position: absolute;
  top: 0px;
  right: 10px;
  font-size: 50px;
  color: white;
  font-weight: 900;
  padding: 5px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  cursor: pointer;
  z-index: 100;
}

/* flash card */
.flashCardAlert {
  background-color: rgb(247, 206, 131);
  padding: 10px 15px;
  box-sizing: border-box;
  width: 100%;
  border-radius: 10px;
  font-family: Montserrat;
  font-weight: 500;
  font-size: 14px;
  border: 2px solid rgb(224, 173, 77);
  text-align: center;
}

.flashCardSuccess {
  background-color: rgb(181, 253, 196);
  padding: 10px 0px;
  width: 100%;
  border-radius: 10px;
  font-family: Montserrat;
  font-weight: 500;
  font-size: 14px;
  border: 2px solid rgb(148, 206, 161);
  text-align: center;
}

.flashCardError {
  background-color: rgb(247, 131, 131);
  padding: 10px 0px;
  width: 100%;
  border-radius: 10px;
  font-family: Montserrat;
  font-weight: 500;
  font-size: 14px;
  border: 2px solid rgb(255, 41, 41);
  text-align: center;
}

.swiper-button-next,
.swiper-button-prev {
  width: 50px !important;
  height: 50px !important;
  border-radius: 50%;
  background-color: #e8e8e8;
  color: #b90504 !important;
}

.swiper-button-next::after,
.swiper-button-prev::after {
  font-size: 25px !important;
  font-weight: 900;
}

#loginBodyTop {
  width: 100%;
  height: 35vh;
  background-color: #b90504;
  display: flex;
  justify-content: center;
}

#loginBodyTop h1 {
  font-family: Spartan;
  color: white;
  font-weight: 900;
  font-size: 60px;
  margin: 35px 0px;
}

#loginBodyBottom {
  position: absolute;
  top: 35vh;
  width: 100%;
  height: 65vh;
  background-color: #333333;
}

#loginContainer {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  width: calc(100% - 10px);
  max-width: 380px;
  height: 280px;
  background-color: rgb(34, 34, 34);
  border-radius: 20px;
}

#loginForm {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  grid-gap: 35px;
  gap: 35px;
}

#loginForm button {
  border: none;
  background-color: #b90504;
  color: white;
  height: 40px;
  padding: 0px 10px;
  border-radius: 20px;
  font-family: Montserrat;
  font-weight: bold;
  cursor: pointer;
}

#goToLoginAndRegister {
  color: white;
  text-decoration: none;
  font-family: Montserrat;
  font-weight: bold;
  position: absolute;
  bottom: 10px;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
}

.businessInventoryMainContainer {
  position: relative;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  margin: 20px 0px;
  max-width: 1400px;
  padding: 0px 10px;
  box-sizing: border-box;
}

.businessInventoryHeaderDiv {
  padding: 0px 10px;
  box-sizing: border-box;
  width: 100%;
  margin-bottom: 7px;
}

#inventoryToolsDiv {
  position: relative;
  width: 100%;
  height: 80px;
  padding: 0px 15px;
  box-sizing: border-box;
  background-color: white;
  border-radius: 20px;
}

#addItemsButton {
  margin-left: auto;
  border: none;
  background-color: #b90504;
  height: 40px;
  padding: 0px 20px;
  color: white;
  font-family: Montserrat;
  font-weight: bold;
  border-radius: 20px;
  cursor: pointer;
}

#addItemsButton:active {
  background-color: #990100;
}

#inventoryToolsDiv input {
  height: 40px;
  padding-left: 20px;
  border-radius: 20px;
  border: none;
  background-color: lightgray;
  font-family: Montserrat;
  font-weight: 600;
  width: 250px;
}

#inventoryHeader {
  font-size: 25px;
  color: dimgray;
  font-family: Montserrat;
  font-weight: 500;
}

#howToUseInventory {
  margin-left: auto;
  color: dimgray;
  font-family: Montserrat;
  cursor: pointer;
}

#howToUseInventory h2 {
  font-weight: 500;
  font-size: 18px;
}

#inventorySearchButton:active {
  background-color: #990100;
}

#inventoryListDiv {
  margin: 10px 0;
  width: 100%;
  background-color: white;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  padding-bottom: 20px;
}

#inventoryColumnLabelDiv {
  width: 100%;
  height: 60px;
  border-bottom: 3px solid #e8e8e8;
  display: grid;
  grid-template-columns: 3fr 1fr 2fr 1fr;
  justify-content: center;
  align-items: center;
}

#inventoryColumnLabelDiv h1 {
  font-family: Montserrat;
  font-weight: 600;
  color: dimgray;
  font-size: 25px;
  text-align: center;
}

.inventoryCard {
  display: grid;
  grid-template-columns: 3fr 1fr 2fr 1fr;
  width: 100%;
  height: 150px;
  border-bottom: 2px solid #e8e8e8;
  align-items: center;
  font-family: Montserrat;
}

.businessInventoryProductImageAndTextDiv {
  padding: 0px 15px;
  grid-gap: 20px;
  gap: 20px;
  height: 150px;
  box-sizing: border-box;
}

.businessInventoryProductImageDiv {
  min-width: 120px;
  max-width: 120px;
  padding: 15px 0px;
  box-sizing: border-box;
  height: 150px;
}

.businessInventoryProductImageDiv img {
  max-height: 100%;
  max-width: 100%;
}

.businessInventoryProductTextDiv {
  padding: 5px 0;
  height: 100%;
  box-sizing: border-box;
  grid-gap: 5px;
  gap: 5px;
}

.businessInventoryProductTextDiv h2 {
  font-size: 16px;
  font-weight: 600;
}

.inventoryPriceColumnDiv h2 {
  text-align: center;
  font-weight: 500;
}

.inventoryAvailabilityColumnDiv {
  display: flex;
  justify-content: center;
  align-items: center;
}

.inventoryOptionColumnDiv {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  grid-gap: 3px;
  gap: 3px;
}

#submitAndUpdateEditPageDiv {
  position: absolute;
  bottom: 10px;
  display: flex;
  grid-gap: 10px;
  gap: 10px;
}

.businessInventoryMobileAvailabilityTag {
  height: 30px;
  background-color: #b90504;
  color: white;
  border-radius: 0 0 0 10px;
  font-weight: 600;
  font-size: 20px;
}

@media (max-width: 780px) {
  .inventoryCard {
    grid-template-columns: 3fr 1.2fr 1fr;
  }

  #inventoryColumnLabelDiv {
    grid-template-columns: 3fr 1.2fr 1fr;
  }

  .businessInventoryProductImageAndTextDiv {
    padding: 10px 15px;
  }

  .businessInventoryProductTextDiv span {
    color: #b90504;
  }

  .businessInventoryProductTextDiv h2 {
    font-size: 16px;
  }

  .businessInventoryProductTextDiv h3 {
    font-weight: 600;
    margin-top: auto;
  }
}

@media (max-width: 480px) {
  .businessInventoryMainContainer {
    margin: 10px 0 0 0;
  }

  .businessInventoryHeaderDiv {
    padding: 0;
  }

  #inventoryToolsDiv {
    height: 55px;
    border-radius: 10px;
    padding: 7px;
  }

  #inventoryToolsDiv input {
    width: 100%;
    border-radius: 7px;
  }

  #inventoryListDiv {
    background-color: transparent;
    grid-gap: 10px;
    gap: 10px;
  }

  .inventoryCard {
    position: relative;
    background-color: white;
    border-radius: 10px;
    display: flex;
    height: 130px;
  }

  .businessInventoryProductImageAndTextDiv {
    padding: 0px 5px 0 0;
    grid-gap: 10px;
    gap: 10px;
    height: 130px;
  }

  .businessInventoryProductImageDiv {
    padding: 0;
    height: 100px;
    min-width: 100px;
    max-width: 100px;
  }

  .businessInventoryProductImageDiv img {
    border-radius: 10px 0 0 0;
  }

  .businessInventoryProductTextDiv span {
    color: #b90504;
  }

  .businessInventoryProductTextDiv h2 {
    font-size: 14px;
  }

  .businessInventoryProductTextDiv h3 {
    font-size: 16px;
    font-weight: 600;
    margin-top: auto;
  }

  .businessInventoryProductTextDiv .groceryTag {
    height: 22px;
    font-size: 12px;
    padding: 0px 5px;
    margin: 2px 0;
  }

  .inventoryOptionColumnDiv .redButton {
    position: absolute;
    bottom: 5px;
    right: 10px;
    height: 30px;
    border-radius: 5px;
    padding: 0px 10px;
  }
}

* {
  margin: 0;
  padding: 0;
}

#navBar {
  width: 100%;
  height: 50px;
  background-color: #b90504;
}

#navTabs {
  display: flex;
  width: 26%;
  height: 50px;
  grid-gap: 25px;
  gap: 25px;
  padding-left: 10px;
  align-items: center;
}

#navTabs a {
  color: white;
  font-size: 140%;
  text-decoration: none;
  align-items: center;
  font-family: Roboto;
  font-weight: 100;
}

#locaroHeader {
  font-family: Spartan;
  font-weight: bold;
  color: white;
}

.navTabs {
  height: 46px;
  display: flex;
  padding: 0px 5px;
}

.navTabActive {
  border-bottom: 4px solid white;
}

#shopDropDown {
  position: absolute;
  display: flex;
  right: 10px;
  top: 0px;
  height: 50px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
  padding-left: 10px;
}

#shopDropDown h1 {
  color: white;
  font-size: 140%;
  text-decoration: none;
  align-items: center;
  font-family: Roboto;
  font-weight: 100;
}

#shopDropDown:hover {
  background-color: #990100;
}

#dropDownDiv {
  position: absolute;
  right: 8px;
  top: 51px;
  border-radius: 8px;
  background-color: #333333;
  padding: 8px 0px;
  z-index: 5;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

#dropDownDiv a {
  text-decoration: none;
  color: white;
}

.dropDownOptionDivs {
  color: white;
  padding: 5px 10px;
  padding-right: 50px;
  cursor: pointer;
  font-family: Roboto;
}

.dropDownOptionDivs:hover {
  background-color: #222222;
}

.dropDownOptionDivs h2 {
  font-weight: 400;
  font-size: 20px;
}

/* mobile styles */
.mobileMenuDiv {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  background-color: #191919;
  width: 280px;
  color: white;
  font-family: Montserrat;
  -webkit-animation-name: menuSlideInLeft;
          animation-name: menuSlideInLeft;
  -webkit-animation-duration: 500ms;
          animation-duration: 500ms;
}

.mobileMenuSections {
  background-color: #222222;
  padding: 10px 0px;
  margin-top: auto;
}

.mobileMenuSections:nth-child(2) {
  padding: 0px 0;
  margin-top: 7px;
  margin-bottom: -7px;
}

.mobileMenuSectionHeader {
  color: white;
  font-size: 22px;
  font-weight: 600;
  margin: 0 0 5px 15px;
}

.mobileMenuLogoDiv {
  background-color: #b90504;
  font-family: Spartan;
  height: 45px;
}

.mobileMenuLogoDiv h1 {
  font-weight: 800;
}

.mobileMenuShopTabs {
  color: white;
  height: 60px;
  font-size: 24px;
  font-weight: 600;
  padding: 0px 15px;
  box-sizing: border-box;
  background-color: #222222;
  border-bottom: 7px solid #191919;
}

.mobileMenuOptions {
  color: white;
  height: 50px;
  font-size: 20px;
  font-weight: 300;
  padding: 0px 30px;
  box-sizing: border-box;
  background-color: #222222;
}

@-webkit-keyframes menuSlideInLeft {
  from {
    left: -280px;
  }
  to {
    left: 0;
  }
}

@keyframes menuSlideInLeft {
  from {
    left: -280px;
  }
  to {
    left: 0;
  }
}

@-webkit-keyframes menuSlideOutLeft {
  from {
    left: 0px;
  }
  to {
    left: -280px;
  }
}

@keyframes menuSlideOutLeft {
  from {
    left: 0px;
  }
  to {
    left: -280px;
  }
}

@media (max-width: 748px) {
  #navTabs {
    grid-gap: 15px;
    gap: 15px;
  }

  #navTabs h1 {
    font-size: 30px;
    margin-bottom: -6px;
  }

  #navTabs img {
    width: 36px;
    height: 36px;
  }
}

#addProductDiv {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  height: 450px;
  min-width: 400px;
  width: 25%;
  border-radius: 20px;
  background-color: #333333;
  display: flex;
  justify-content: center;
  align-items: center;
}

#addProductForm {
  position: absolute;
  top: 60px;
  display: flex;
  flex-direction: column;
  width: 95%;
  grid-gap: 15px;
  gap: 15px;
  justify-content: center;
  align-items: center;
  height: calc(100% - 60px);
}

#addProductForm h1 {
  color: white;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 500;
  margin-left: 5px;
}

#addProductForm h2 {
  color: white;
  font-family: Montserrat;
  font-size: 10px;
  font-weight: 500;
  margin-left: 5px;
}

#addProductForm input {
  width: 100%;
  height: 44px;
  border-radius: 10px;
  font-family: Montserrat;
  font-weight: bold;
  padding-left: 15px;
  font-size: 20px;
  box-sizing: border-box;
}

.addProductSearchButton {
  position: absolute;
  top: 68px;
  right: 3px;
  border-radius: 7px;
  height: 35px;
  width: 35px;
  background-color: #b90504;
}

.addProductSearchButton img {
  height: 30px;
  width: 30px;
}

#isAvailableButton {
  background-color: green;
  border: none;
  height: 40px;
  border-radius: 20px;
  width: 60%;
  color: white;
  font-family: Montserrat;
  font-weight: bold;
  cursor: pointer;
}

#addProductSubmitButton:hover {
  background-color: #990100;
}

.addProductSearchInputDiv {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -180px);
          transform: translate(-50%, -180px);
  z-index: 2;
  width: 90%;
}

.inventoryAddProductSearchSuggestions {
  height: 250px;
  width: calc(95% + 15px);
  background-color: white;
  border-radius: 0px 0px 20px 20px;
  overflow-y: scroll;
}

.inventoryAddProductSearchSuggestions::-webkit-scrollbar {
  width: 8px;
}

.inventoryAddProductSearchSuggestions::-webkit-scrollbar-thumb {
  background-color: #333333;
  border-radius: 4px;
}

.inventoryAddProductSuggestionCard {
  color: #333333;
  padding: 10px;
  font-family: Montserrat;
  font-weight: 500;
  font-size: 12px;
  cursor: pointer;
  border-bottom: 2px solid #e8e8e8;
}

.inventoryAddProductSuggestionCard:hover {
  background-color: #e6e6e6;
}

@media (max-width: 480px) {
  #addProductDiv {
    width: 95%;
    min-width: unset;
  }

  .inventoryAddProductSearchSuggestions {
    width: calc(95% + 15px);
  }
}

#ordersPageMainContainer {
  position: relative;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  max-width: 1400px;
  margin: 20px 0px;
  padding: 0px 20px;
  box-sizing: border-box;
  justify-content: start;
}

#orderTabsContainer {
  background-color: white;
  height: 60px;
  width: 100%;
  border-radius: 15px;
  display: flex;
  justify-content: left;
  align-items: center;
}

.orderTabs {
  color: dimgray;
  padding: 0px 20px;
  font-family: Roboto;
  cursor: pointer;
}

.orderTabsActive {
  background-color: #b90504;
  height: 40px;
  color: white;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.orderTabs h2 {
  display: flex;
  grid-gap: 7px;
  gap: 7px;
  align-items: center;
  font-size: 24px;
}

.orderTabs span {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #e8e8e8;
  width: 25px;
  height: 25px;
  border-radius: 15px;
  font-size: 15px;
}

.orderTabs.orderTabsActive span {
  background-color: white;
  color: #b90504;
}

.ordersContainer {
  flex-wrap: wrap;
  margin: 20px 0 0 0;
  grid-gap: 10px;
  gap: 10px;
  width: 100%;
}

.orderCard {
  position: relative;
  background-color: white;
  width: calc(100% / 3 - 7.5px);
  border-radius: 10px;
  padding: 40px 7px 10px 7px;
  box-sizing: border-box;
}

.orderTag {
  position: absolute;
  left: 0px;
  top: 0px;
  border-radius: 10px 0px;
  height: 30px;
  font-family: Montserrat;
  color: white;
  font-weight: 600;
  padding: 0px 10px;
  display: flex;
  align-items: center;
}

.orderTag.Pending {
  background-color: rgb(255, 191, 0);
}

.orderTag.Active {
  background-color: rgb(80, 168, 255);
}

.orderTag.Cancelled {
  background-color: #b90504;
}

.ordersAddressDiv {
  font-family: Montserrat;
  border-radius: 5px;
  background-color: #e8e8e8;
  padding: 5px 10px;
  box-sizing: border-box;
  width: 100%;
  font-size: 14px;
  margin: 0 0 5px 0;
  height: 60px;
}

.ordersAddressDiv span {
  font-weight: 600;
}

.ordersOrderedProductsDiv {
  background-color: #e8e8e8;
  font-family: Montserrat;
  border-radius: 5px;
  padding: 5px 0;
  height: 185px;
  box-sizing: border-box;
  overflow-y: scroll;
  overflow-x: hidden;
}

.ordersOrderedProductsDiv::-webkit-scrollbar {
  width: 6px;
}

.ordersOrderedProductsDiv::-webkit-scrollbar-thumb {
  background-color: #333333;
  border-radius: 3px;
}

.ordersItemDiv {
  grid-gap: 20px;
  gap: 20px;
  padding: 9px 10px;
  box-sizing: border-box;
  border-bottom: 2px solid white;
}

.ordersItemDiv h2 {
  font-size: 12px;
  font-weight: 400;
  width: 75%;
}

.ordersItemDiv h3 {
  font-size: 15px;
  font-weight: 500;
  max-width: 30%;
  min-width: 25%;
  text-align: right;
}

.ordersButtonDiv {
  width: 100%;
  grid-gap: 10px;
  gap: 10px;
  margin-top: 10px;
}

.ordersButton {
  width: 50%;
  height: 40px;
  border-radius: 5px;
  border: none;
  color: white;
  font-weight: bolder;
  font-size: 20px;
  cursor: pointer;
}

.ordersButton:nth-of-type(1) {
  background-color: green;
}

.ordersButton:nth-of-type(2) {
  background-color: #b90504;
}

.ordersButton:nth-of-type(1):hover {
  background-color: darkgreen;
}

.ordersButton:nth-of-type(2):hover {
  background-color: #990100;
}

@media (max-width: 780px) {
  #ordersPageMainContainer {
    padding: 0px 10px;
  }

  .orderCard {
    width: calc(50% - 5px);
  }
}

@media (max-width: 480px) {
  #ordersPageMainContainer {
    padding: 0px 5px;
  }

  .orderCard {
    width: 100%;
  }

  #orderTabsContainer {
    overflow-x: scroll;
    padding: 0 10px;
    box-sizing: border-box;
  }

  .ordersContainer {
    margin: 10px 0 0 0;
  }

  .orderTabs {
    margin: 0 !important;
  }
}

.businessRegisterPageContainer {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  max-width: 750px;
  width: 100%;
  height: 600px;
  border-radius: 15px;
  overflow: hidden;
}

.businessRegisterPageLeftDiv {
  position: relative;
  background-color: #b90504;
  width: 300px;
  height: 100%;
  color: white;
  font-family: Montserrat;
  text-align: center;
  padding-bottom: 20px;
  box-sizing: border-box;
}

.businessRegisterPageLeftDiv h1 {
  font-weight: 500;
  margin-top: 120px;
}

.businessRegisterPageLeftDiv h2 {
  margin-top: 50px;
  font-weight: 400;
  font-size: 17px;
}

.businessRegisterPageLeftDiv div {
  margin-top: auto;
}

.businessRegisterPageLeftDiv p {
  font-size: 15px;
}

.businessRegisterPageLeftDiv button {
  height: 30px;
  background: transparent;
  border: 2px solid white;
  font-family: Roboto;
  color: white;
  font-size: 16px;
  border-radius: 15px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  padding: 0px 12px;
  cursor: pointer;
  margin-top: 10px;
  transition: all 200ms ease-in;
}

.businessRegisterPageLeftDiv button:hover {
  background: white;
  color: #b90504;
}

.businessRegisterPageLeftDiv img {
  position: absolute;
  top: 15px;
  left: 20px;
  width: 50px;
  height: 50px;
}

.businessRegisterPageRightDiv {
  background-color: #333333;
  width: calc(100% - 300px);
  height: 100%;
}

.businessRegisterPageTabsContainer {
  width: 100%;
  height: 80px;
  color: white;
  border-bottom: 4px solid #403d3d;
  font-family: Montserrat;
  grid-gap: 10px;
  gap: 10px;
}

.businessRegisterPageTabs {
  width: 80px;
  height: 80px;
  color: dimgray;
}

.businessRegisterPageTabs.completed {
  color: white;
}

.businessRegisterPageTabs.completed svg {
  fill: white;
}

.businessRegisterPageTabs p {
  font-size: 13px;
  font-weight: 500;
}

.businessRegisterPageTabs svg {
  fill: dimgray;
  width: 40px;
  height: 40px;
}

.businessRegisterPageForm {
  height: calc(100% - 84px);
  width: 100%;
  position: relative;
  grid-gap: 15px;
  gap: 15px;
  padding: 30px 0px 60px 0px;
  box-sizing: border-box;
}

.businessRegisterPageForm p {
  color: white;
  font-family: Montserrat;
  width: calc(70% + 30px);
  font-size: 13px;
}

.businessRegisterPageForm button[type="submit"],
.businessRegisterPageMapForm button[type="submit"] {
  position: absolute;
  bottom: 15px;
  overflow: hidden;
}

.businessRegisterPageMapForm {
  height: calc(100% - 84px);
  width: 100%;
  position: relative;
  grid-gap: 15px;
  gap: 15px;
}

.businessRegisterPageMapDiv {
  width: 100%;
  position: relative;
  box-sizing: border-box;
}

.businessRegisterPageMapDiv div:nth-child(1) {
  aspect-ratio: 16/9;
  height: unset !important;
}

.businessRegisterPageSetLocation {
  position: absolute;
  left: 50%;
  bottom: 5px;
  -webkit-transform: translate(-50%, 0);
          transform: translate(-50%, 0);
}

.businessRegisterPageDisabledInput {
  width: 70%;
  height: 40px;
  border-radius: 10px;
  padding: 0px 15px;
  font-family: Montserrat;
  font-weight: 600;
  font-size: 22px;
  color: white;
  border: 2px solid white;
}

@media (max-width: 750px) {
  .businessRegisterPageContainer {
    flex-direction: column-reverse;
    min-height: 100%;
    height: auto;
    border-radius: 0;
    position: relative;
    top: 0;
    left: 0;
    -webkit-transform: unset;
            transform: unset;
  }

  .businessRegisterPageRightDiv {
    position: relative;
    min-height: calc(100vh - 140px);
    height: unset;
    width: 100%;
  }

  .businessRegisterPageLeftDiv {
    height: 140px;
    width: 100%;
    padding: 0;
  }

  .businessRegisterPageForm {
    position: unset;
    height: 100%;
  }

  .businessRegisterPageMapForm {
    padding-bottom: 70px;
    position: unset;
  }

  .businessRegisterPageForm button[type="submit"],
  .businessRegisterPageMapForm button[type="submit"] {
    position: absolute;
  }

  .businessRegisterPageLeftDiv h1,
  .businessRegisterPageLeftDiv h2,
  .businessRegisterPageLeftDiv img {
    display: none;
  }

  .businessRegisterPageLeftDiv div {
    margin: auto;
  }
}

@media (max-width: 480px) {
  .businessRegisterPageMapDiv div:nth-child(1) {
    aspect-ratio: 16/11;
  }
}

.componentLoadingDiv {
  background-color: rgba(0, 0, 0, 0.6);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}

#editProductDiv {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  height: 250px;
  min-width: 400px;
  width: 25%;
  border-radius: 20px;
  background-color: #333333;
  display: flex;
  justify-content: center;
}

#editProductForm {
  display: flex;
  flex-direction: column;
  width: 95%;
  grid-gap: 15px;
  gap: 15px;
  justify-content: center;
  align-items: center;
  height: calc(100% - 40px);
}

#editProductForm div {
  color: white;
  font-family: Montserrat;
}

#editProductForm div h5 {
  margin-left: 5px;
}

#editProductForm div p {
  margin-left: 5px;
}

#editProductForm input {
  width: 100%;
  height: 44px;
  border-radius: 10px;
  font-family: Montserrat;
  font-weight: bold;
  padding-left: 15px;
  font-size: 20px;
  box-sizing: border-box;
}

@media (max-width: 480px) {
  #editProductDiv {
    width: 95%;
    min-width: unset;
  }
}

.howToJumpLinksDiv {
  position: absolute;
  top: 50px;
  left: 2%;
  width: 20%;
  display: flex;
  flex-direction: column;
  grid-gap: 5px;
  gap: 5px;
}

.howToJumpLinksDiv h1 {
  font-family: Spartan;
  margin: 5px 0px;
}

.howToJumpLinks {
  text-decoration: none;
  width: 100%;
  height: 35px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  color: dimgray;
}

.howToJumpLinks h3 {
  margin: 0px 10px;
  font-family: Roboto;
}

.howToJumpLinks:hover {
  background-color: #b90105;
  color: white;
}

.howToInfoContainer {
  position: absolute;
  left: 24%;
  width: 66%;
  height: 100vh;
  padding: 0px 40px;
  border-left: 1px solid grey;
  overflow-x: hidden;
  overflow-x: auto;
  scroll-behavior: smooth;
}

.howToInfoContainer h1 {
  margin: 30px 0px;
}

.howToOrderedList li {
  margin: 10px;
}

#dashboardMainContainer {
  margin-top: 30px;
  position: relative;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  max-width: 1200px;
  padding: 0px 5px;
  box-sizing: border-box;
  grid-gap: 10px;
  gap: 10px;
}

.infoCardParentDiv {
  width: 25%;
  grid-gap: 10px;
  gap: 10px;
}

.dashboardRightDiv {
  width: 75%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  font-family: Montserrat;
  grid-gap: 15px;
  gap: 15px;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
}

.dashboardRightDivCard h1 {
  font-size: 17px;
  font-weight: 500;
  color: dimgray;
}

.dashboardRightDivCard p {
  font-size: 40px;
  font-weight: 700;
  margin-top: 5px;
}

.dashboardRightDivCard span {
  font-size: 20px;
  margin-top: 2px;
  font-weight: 700;
  color: dimgray;
}

.dashboardRightDivCard::-webkit-scrollbar {
  width: 8px;
}

.dashboardRightDivCard::-webkit-scrollbar-thumb {
  background-color: #333333;
  border-radius: 4px;
}

.dashboardRightDivCard {
  word-break: break-word;
  background-color: white;
  border-radius: 20px;
  padding: 15px;
  box-sizing: border-box;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
}

.dashboardPreviousEarningsDiv {
  word-break: break-word;
  background-color: white;
  border-radius: 20px;
  padding: 15px;
  box-sizing: border-box;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
}

.infoCard {
  background-color: white;
  width: 100%;
  border-radius: 20px;
  overflow: hidden;
}

.shopInfoImageDiv {
  background-image: url(https://images.unsplash.com/photo-1528698827591-e19ccd7bc23d?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1476&q=80);
  background-size: 100%;
  width: 100%;
  aspect-ratio: 16/9;
  position: relative;
}

.shopImageOverlayDiv {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  grid-gap: 10px;
  gap: 10px;
  align-items: flex-end;
  padding-bottom: 10px;
  box-sizing: border-box;
  transition: background-color 200ms ease-in;
}

.infoCard:hover .shopImageOverlayDiv {
  background-color: rgba(0, 0, 0, 0);
}

.shopRatingDiv {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  padding: 3px 5px;
  margin-left: 10px;
  background-color: #b90504;
  color: white;
  font-family: Montserrat;
  font-weight: bolder;
  border-radius: 5px;
  font-size: 18px;
}

.shopImageOverlayDiv {
  font-size: 10px;
  color: white;
  font-family: Montserrat;
  margin: 0px 0px;
}

.shopInfoTextDiv {
  font-family: Montserrat;
  padding: 20px 15px;
  font-size: 15px;
  width: 100%;
  color: #222222;
  box-sizing: border-box;
}

.shopInfoTextDiv h1 {
  font-size: 20px;
}

.shopInfoTextDiv h2 {
  color: #333333;
  margin-top: 15px;
  font-size: 18px;
  font-weight: 600;
}

#shopInfoImageUpdate {
  display: none;
}

@media (max-width: 780px) {
  .infoCardParentDiv {
    width: 100%;
  }
}

.landingPageBody {
  width: 100%;
  min-height: 100vh;
  background: #b90504;
  transition: background 500ms ease-in;
}

.landingPageNavBarDiv {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 90px;
  background: #b90504;
  transition: top 150ms ease-in, background 400ms ease-in;
  color: white;
  padding: 0px 40px;
  box-sizing: border-box;
  opacity: 0.98;
  z-index: 5;
}

.landingPageNavBarDiv h1 {
  font-family: Montserrat;
  font-size: 50px;
  font-weight: 700;
}

.landingPageNavBarLinksDiv {
  margin-left: auto;
  grid-gap: 40px;
  gap: 40px;
  font-family: Montserrat;
}

.landingPageNavBarLinksDiv a {
  color: white;
  text-decoration: none;
  font-size: 20px;
}

#homeSection {
  width: 100%;
  height: 100vh;
}

#detailsSection {
  width: 100%;
}

#footer {
  width: 100%;
  height: 250px;
  background-color: #b90504;
  margin-top: 40px;
  grid-gap: 50px;
  gap: 50px;
}

.homeSectionContentContainer {
  grid-gap: 0px;
  gap: 0px;
  padding-right: 50px;
}

.homeSectionTextDiv {
  color: white;
  font-family: Noto Sans;
  width: 700px;
  grid-gap: 10px;
  gap: 10px;
}

.homeSectionTextDiv h1 {
  font-size: 60px;
  font-weight: 700;
  line-height: 65px;
}

.homeSectionTextDiv p {
  font-family: Roboto;
  font-size: 25px;
  font-weight: 300;
}

.homeSectionTextDiv a {
  height: 40px;
  background: transparent;
  border: 2px solid white;
  font-family: Roboto;
  color: white;
  font-size: 20px;
  border-radius: 20px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  padding: 0px 20px;
  margin-top: 30px;
  cursor: pointer;
  transition: all 200ms ease-in;
}

.homeSectionTextDiv a:hover {
  background: white;
  color: #b90504;
}

.homeSectionImageDiv {
  width: 500px;
  flex-shrink: 0;
}

.homeSectionImageDiv img {
  width: 100%;
  aspect-ratio: 1/1;
  -webkit-transform: rotate(-23.53deg) translateY(-20px);
          transform: rotate(-23.53deg) translateY(-20px);
}

.landingPageHowItWorksDiv {
  grid-gap: 40px;
  gap: 40px;
  margin-top: 50px;
  font-family: Montserrat;
  color: white;
}

.landingPageHowItWorksCardDiv {
  grid-gap: 30px;
  gap: 30px;
}

.landingPageHowItWorksCard {
  width: 300px;
  height: 250px;
  border-radius: 20px;
  border: 4px solid #b90504;
  grid-gap: 10px;
  gap: 10px;
  padding: 0px 30px;
  box-sizing: border-box;
  text-align: center;
  opacity: 0;
  transition: opacity 500ms ease-in;
}

.landingPageHowItWorksCard h2 {
  font-size: 28px;
}

.landingPageHowItWorksCard p {
  font-size: 22px;
}

.landingPageAppear {
  opacity: 1;
}

.landingPageShopSwiper {
  width: 100%;
}

.landingPageShopCard {
  position: relative;
  width: 100%;
  aspect-ratio: 16/9;
  background-size: 100%;
  border-radius: 40px;
  margin: 40px 0px;
  overflow: hidden;
}

.landingPageShopOverlay {
  width: 100%;
  height: 100%;
  background-color: rgb(0, 0, 0, 0.6);
  color: white;
  font-family: Montserrat;
  text-align: center;
  padding: 0px 100px;
  box-sizing: border-box;
}

.landingPageShopOverlay h1 {
  font-size: 30px;
}

.landingPageShopOverlay p {
  font-size: 20px;
  margin-top: 40px;
}

.landingPageShopOverlay h3 {
  font-size: 20px;
}

.landingPageShopRating {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  padding: 3px 5px;
  font-weight: bolder;
  border-radius: 5px;
  font-size: 15px;
}

.landingPageFooterLogoImageDiv {
  margin: auto 40px auto 60px;
}

.landingPageFooterLogoImageDiv img {
  -webkit-transform: rotate(-23.53deg);
          transform: rotate(-23.53deg);
  height: 100px;
  width: 100px;
}

.landingPageFooterColumn {
  height: 100%;
  padding-top: 40px;
  box-sizing: border-box;
  color: white;
  font-family: Montserrat;
  grid-gap: 20px;
  gap: 20px;
}

.landingPageFooterColumn a {
  font-size: 20px;
  font-weight: 500;
  text-decoration: none;
  color: white;
}

.landingPageFooterLinkSVG {
  height: 30px;
  width: 30px;
  fill: white;
}

.landingPageFooterSupportDiv {
  margin: auto 100px auto auto;
  color: white;
  font-family: Montserrat;
  width: 250px;
}

.landingPageFooterSupportDiv h2 {
  padding: 10px 0px;
  border-bottom: 2px solid white;
  text-align: center;
  width: 200px;
}

.landingPageFooterSupportDiv p {
  text-align: center;
  margin: 20px 0px;
}

.landingPageFooterSupportDiv span {
  font-weight: 600;
}

.landingPageMobileMenu {
  position: fixed;
  right: 0;
  min-width: 280px;
  height: 100%;
  background-color: #191919;
  color: white;
  -webkit-animation-name: menuSlideInRight;
          animation-name: menuSlideInRight;
  -webkit-animation-duration: 500ms;
          animation-duration: 500ms;
}

.landingPageMobileMenuTopBar {
  background-color: #b90504;
  font-family: Spartan;
  height: 45px;
  font-size: 28px;
  font-weight: 800;
}

.landingPageMobileMenuOptions {
  background-color: #222222;
  padding: 15px 0 15px 20px;
  margin-top: 10px;
  box-sizing: border-box;
  font-size: 25px;
  font-family: Montserrat;
  font-weight: 500;
  color: white;
}

@-webkit-keyframes menuSlideInRight {
  from {
    right: -280px;
  }
  to {
    right: 0;
  }
}

@keyframes menuSlideInRight {
  from {
    right: -280px;
  }
  to {
    right: 0;
  }
}

@-webkit-keyframes menuSlideOutRight {
  from {
    right: 0px;
  }
  to {
    right: -280px;
  }
}

@keyframes menuSlideOutRight {
  from {
    right: 0px;
  }
  to {
    right: -280px;
  }
}

@media (max-width: 1325px) {
  .homeSectionImageDiv {
    width: 350px;
  }

  .homeSectionTextDiv {
    width: 550px;
  }

  .landingPageFooterSupportDiv {
    margin-right: 20px;
  }
}

@media (max-width: 780px) {
  #homeSection {
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    padding: 100px 0px;
  }

  .landingPageNavBarDiv {
    padding: 0px 20px;
    height: 70px;
  }

  .landingPageNavBarDiv h1 {
    font-size: 40px;
  }

  .landingPageNavBarLinksDiv {
    display: none;
  }

  .homeSectionContentContainer {
    flex-direction: column;
    padding: 0;
    grid-gap: 50px;
    gap: 50px;
  }

  .homeSectionTextDiv {
    width: 100%;
    align-items: center;
    text-align: center;
  }

  .homeSectionImageDiv {
    width: 100%;
  }

  .landingPageHowItWorksCardDiv {
    flex-direction: column;
  }

  .landingPageShopCard {
    border-radius: 20px;
  }

  .landingPageFooterSupportDiv {
    margin-right: 20px;
  }
}

@media (max-width: 480px) {
  .landingPageNavBarDiv {
    padding: 0px 15px;
  }

  .homeSectionTextDiv h1 {
    font-size: 40px;
    line-height: 45px;
  }

  .homeSectionTextDiv a {
    margin-top: 15px;
  }

  .homeSectionImageDiv img {
    width: 80%;
  }

  #footer {
    flex-direction: column;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    align-items: center;
    grid-gap: 40px;
    gap: 40px;
  }

  .landingPageFooterColumn {
    padding: 0;
  }

  .landingPageFooterLogoImageDiv {
    margin: 20px auto 0 auto;
  }

  .landingPageFooterSupportDiv {
    margin: 0px auto;
  }

  .landingPageFooterSupportDiv div {
    width: 100%;
  }

  .landingPageShopCard {
    border-radius: 15px;
  }

  .landingPageShopOverlay {
    padding: 0px 20px;
  }

  .landingPageShopOverlay h1 {
    font-size: 22px;
  }

  .landingPageShopOverlay p {
    font-size: 16px;
  }
}

