.howToJumpLinksDiv {
  position: absolute;
  top: 50px;
  left: 2%;
  width: 20%;
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.howToJumpLinksDiv h1 {
  font-family: Spartan;
  margin: 5px 0px;
}

.howToJumpLinks {
  text-decoration: none;
  width: 100%;
  height: 35px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  color: dimgray;
}

.howToJumpLinks h3 {
  margin: 0px 10px;
  font-family: Roboto;
}

.howToJumpLinks:hover {
  background-color: #b90105;
  color: white;
}

.howToInfoContainer {
  position: absolute;
  left: 24%;
  width: 66%;
  height: 100vh;
  padding: 0px 40px;
  border-left: 1px solid grey;
  overflow-x: hidden;
  overflow-x: auto;
  scroll-behavior: smooth;
}

.howToInfoContainer h1 {
  margin: 30px 0px;
}

.howToOrderedList li {
  margin: 10px;
}
