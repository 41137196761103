* {
  margin: 0;
  padding: 0;
}

#navBar {
  width: 100%;
  height: 50px;
  background-color: #b90504;
}

#navTabs {
  display: flex;
  width: 26%;
  height: 50px;
  gap: 25px;
  padding-left: 10px;
  align-items: center;
}

#navTabs a {
  color: white;
  font-size: 140%;
  text-decoration: none;
  align-items: center;
  font-family: Roboto;
  font-weight: 100;
}

#locaroHeader {
  font-family: Spartan;
  font-weight: bold;
  color: white;
}

.navTabs {
  height: 46px;
  display: flex;
  padding: 0px 5px;
}

.navTabActive {
  border-bottom: 4px solid white;
}

#shopDropDown {
  position: absolute;
  display: flex;
  right: 10px;
  top: 0px;
  height: 50px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  user-select: none;
  padding-left: 10px;
}

#shopDropDown h1 {
  color: white;
  font-size: 140%;
  text-decoration: none;
  align-items: center;
  font-family: Roboto;
  font-weight: 100;
}

#shopDropDown:hover {
  background-color: #990100;
}

#dropDownDiv {
  position: absolute;
  right: 8px;
  top: 51px;
  border-radius: 8px;
  background-color: #333333;
  padding: 8px 0px;
  z-index: 5;
  user-select: none;
}

#dropDownDiv a {
  text-decoration: none;
  color: white;
}

.dropDownOptionDivs {
  color: white;
  padding: 5px 10px;
  padding-right: 50px;
  cursor: pointer;
  font-family: Roboto;
}

.dropDownOptionDivs:hover {
  background-color: #222222;
}

.dropDownOptionDivs h2 {
  font-weight: 400;
  font-size: 20px;
}

/* mobile styles */
.mobileMenuDiv {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  background-color: #191919;
  width: 280px;
  color: white;
  font-family: Montserrat;
  animation-name: menuSlideInLeft;
  animation-duration: 500ms;
}

.mobileMenuSections {
  background-color: #222222;
  padding: 10px 0px;
  margin-top: auto;
}

.mobileMenuSections:nth-child(2) {
  padding: 0px 0;
  margin-top: 7px;
  margin-bottom: -7px;
}

.mobileMenuSectionHeader {
  color: white;
  font-size: 22px;
  font-weight: 600;
  margin: 0 0 5px 15px;
}

.mobileMenuLogoDiv {
  background-color: #b90504;
  font-family: Spartan;
  height: 45px;
}

.mobileMenuLogoDiv h1 {
  font-weight: 800;
}

.mobileMenuShopTabs {
  color: white;
  height: 60px;
  font-size: 24px;
  font-weight: 600;
  padding: 0px 15px;
  box-sizing: border-box;
  background-color: #222222;
  border-bottom: 7px solid #191919;
}

.mobileMenuOptions {
  color: white;
  height: 50px;
  font-size: 20px;
  font-weight: 300;
  padding: 0px 30px;
  box-sizing: border-box;
  background-color: #222222;
}

@keyframes menuSlideInLeft {
  from {
    left: -280px;
  }
  to {
    left: 0;
  }
}

@keyframes menuSlideOutLeft {
  from {
    left: 0px;
  }
  to {
    left: -280px;
  }
}

@media (max-width: 748px) {
  #navTabs {
    gap: 15px;
  }

  #navTabs h1 {
    font-size: 30px;
    margin-bottom: -6px;
  }

  #navTabs img {
    width: 36px;
    height: 36px;
  }
}
