@import url(https://fonts.googleapis.com/css?family=Spartan:100,200,300,400,500,600,700,800,900&display=swap%27);
@import url(https://fonts.googleapis.com/css?family=Roboto:100,200,300,400,500,600,700,800,900&display=swap%27);
@import url(https://fonts.googleapis.com/css?family=Montserrat:100,200,300,400,500,600,700,800,900&display=swap%27);
@import url(https://fonts.googleapis.com/css?family=Noto+Sans:100,200,300,400,500,600,700,800,900&display=swap%27);

* {
  margin: 0;
  padding: 0%;
}

body {
  background-color: #e8e8e8;
}

#root {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.redButton {
  border: none;
  background-color: #b90504;
  height: 40px;
  padding: 0px 20px;
  color: white;
  font-family: Montserrat;
  font-weight: bold;
  border-radius: 20px;
  cursor: pointer;
  width: fit-content;
}

.overlayDiv {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.85);
  z-index: 10;
  animation-duration: 500ms;
}

@keyframes overlayFadeIn {
  from {
    background-color: rgba(0, 0, 0, 0);
  }
  to {
    background-color: rgba(0, 0, 0, 0.85);
  }
}

@keyframes overlayFadeOut {
  from {
    background-color: rgba(0, 0, 0, 0.85);
  }
  to {
    background-color: rgba(0, 0, 0, 0);
  }
}

.textDecorNone {
  text-decoration: none;
}

.animatedInputDivs {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.animatedInputLabels {
  position: absolute;
  left: 15%;
  font-family: Montserrat;
  font-weight: bold;
  color: dimgray;
  font-size: 20px;
  user-select: none;
  pointer-events: none;
  background-color: rgb(34, 34, 34);
  transition: transform 200ms ease-in, font-size 200ms ease-in,
    padding 200ms ease-in, color 100ms ease-in;
}

.animatedInputInputs {
  width: 70%;
  height: 40px;
  border-radius: 10px;
  font-family: Montserrat;
  font-weight: bold;
  font-size: 20px;
  padding: 0px 15px;
  background-color: transparent;
  border: 2px solid white;
  color: white;
}

.animatedInputInputs:focus {
  outline: none;
  border: 2px solid #b90504;
}

/* main labels */
.animatedInputInputs:focus ~ .animatedInputLabels,
.animatedInputInputs:not(:placeholder-shown) ~ .animatedInputLabels {
  transform: translate(-4px, -20px);
  font-size: 12px;
  padding: 0px 3px;
  color: #b90504;
}

.animatedInputInputs:not(:placeholder-shown).animatedInputInputs:not(:focus)
  ~ .animatedInputLabels {
  color: white;
}

/* lighter gray labels */
.animatedInputLabelsLighterGray {
  position: absolute;
  left: 15%;
  font-family: Montserrat;
  font-weight: bold;
  color: dimgray;
  font-size: 20px;
  user-select: none;
  pointer-events: none;
  background-color: #333333;
  transition: transform 200ms ease-in, font-size 200ms ease-in,
    padding 200ms ease-in, color 100ms ease-in;
}

.animatedInputInputs:focus ~ .animatedInputLabelsLighterGray,
.animatedInputInputs:not(:placeholder-shown) ~ .animatedInputLabelsLighterGray {
  transform: translate(-4px, -20px);
  font-size: 12px;
  padding: 0px 3px;
  color: #b90504;
}

.animatedInputInputs:not(:placeholder-shown).animatedInputInputs:not(:focus)
  ~ .animatedInputLabelsLighterGray {
  color: white;
}

/* smaller gray labels */
.animatedInputLabelsSmallerLighterGray {
  position: absolute;
  left: 15%;
  font-family: Montserrat;
  font-weight: bold;
  color: dimgray;
  font-size: 13px;
  background-color: #333333;
  user-select: none;
  pointer-events: none;
  transition: transform 200ms ease-in, font-size 200ms ease-in,
    padding 200ms ease-in, color 100ms ease-in;
}

.animatedInputInputs:focus ~ .animatedInputLabelsSmallerLighterGray,
.animatedInputInputs:not(:placeholder-shown)
  ~ .animatedInputLabelsSmallerLighterGray {
  transform: translate(-4px, -20px);
  font-size: 10px;
  padding: 0px 3px;
  color: #b90504;
}

.animatedInputInputs:not(:placeholder-shown).animatedInputInputs:not(:focus)
  ~ .animatedInputLabelsSmallerLighterGray {
  color: white;
}

/* animated box shadow */
.card-boxshadow {
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
}

.card-boxshadow:hover {
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
}

.text1Line {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  line-clamp: 1;
  -webkit-box-orient: vertical;
}

.text2Lines {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
}

.text3Lines {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  line-clamp: 3;
  -webkit-box-orient: vertical;
}

.text4Lines {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  line-clamp: 4;
  -webkit-box-orient: vertical;
}

/* flexboxes */
.flex {
  display: flex;
}

.flexColumn {
  display: flex;
  flex-direction: column;
}

.flexJustifyAndAlignCenter {
  justify-content: center;
  align-items: center;
}

.flexJustifyCenter {
  justify-content: center;
}

.flexAlignCenter {
  align-items: center;
}

.flexJustifyRight {
  justify-content: right;
}

.flexJustifyLeft {
  justify-content: left;
}

.flexAlignStart {
  align-items: flex-start;
}

.flexAlignEnd {
  align-items: flex-end;
}

.fileInputLabel {
  background-color: #b90504;
  color: white;
  font-family: Montserrat;
  height: 40px;
  border-radius: 20px;
  padding: 0px 20px;
  font-weight: bold;
}

.groceryTag {
  background-color: green;
  margin: 5px 0px;
  height: 25px;
  width: fit-content;
  padding: 0px 10px;
  color: white;
  font-family: Montserrat;
  font-weight: bold;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: default;
}

.closeButton {
  position: absolute;
  top: 0px;
  right: 10px;
  font-size: 50px;
  color: white;
  font-weight: 900;
  padding: 5px;
  width: fit-content;
  height: fit-content;
  cursor: pointer;
  z-index: 100;
}

/* flash card */
.flashCardAlert {
  background-color: rgb(247, 206, 131);
  padding: 10px 15px;
  box-sizing: border-box;
  width: 100%;
  border-radius: 10px;
  font-family: Montserrat;
  font-weight: 500;
  font-size: 14px;
  border: 2px solid rgb(224, 173, 77);
  text-align: center;
}

.flashCardSuccess {
  background-color: rgb(181, 253, 196);
  padding: 10px 0px;
  width: 100%;
  border-radius: 10px;
  font-family: Montserrat;
  font-weight: 500;
  font-size: 14px;
  border: 2px solid rgb(148, 206, 161);
  text-align: center;
}

.flashCardError {
  background-color: rgb(247, 131, 131);
  padding: 10px 0px;
  width: 100%;
  border-radius: 10px;
  font-family: Montserrat;
  font-weight: 500;
  font-size: 14px;
  border: 2px solid rgb(255, 41, 41);
  text-align: center;
}

.swiper-button-next,
.swiper-button-prev {
  width: 50px !important;
  height: 50px !important;
  border-radius: 50%;
  background-color: #e8e8e8;
  color: #b90504 !important;
}

.swiper-button-next::after,
.swiper-button-prev::after {
  font-size: 25px !important;
  font-weight: 900;
}
