#editProductDiv {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 250px;
  min-width: 400px;
  width: 25%;
  border-radius: 20px;
  background-color: #333333;
  display: flex;
  justify-content: center;
}

#editProductForm {
  display: flex;
  flex-direction: column;
  width: 95%;
  gap: 15px;
  justify-content: center;
  align-items: center;
  height: calc(100% - 40px);
}

#editProductForm div {
  color: white;
  font-family: Montserrat;
}

#editProductForm div h5 {
  margin-left: 5px;
}

#editProductForm div p {
  margin-left: 5px;
}

#editProductForm input {
  width: 100%;
  height: 44px;
  border-radius: 10px;
  font-family: Montserrat;
  font-weight: bold;
  padding-left: 15px;
  font-size: 20px;
  box-sizing: border-box;
}

@media (max-width: 480px) {
  #editProductDiv {
    width: 95%;
    min-width: unset;
  }
}
