#dashboardMainContainer {
  margin-top: 30px;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  max-width: 1200px;
  padding: 0px 5px;
  box-sizing: border-box;
  gap: 10px;
}

.infoCardParentDiv {
  width: 25%;
  gap: 10px;
}

.dashboardRightDiv {
  width: 75%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  font-family: Montserrat;
  gap: 15px;
  height: fit-content;
}

.dashboardRightDivCard h1 {
  font-size: 17px;
  font-weight: 500;
  color: dimgray;
}

.dashboardRightDivCard p {
  font-size: 40px;
  font-weight: 700;
  margin-top: 5px;
}

.dashboardRightDivCard span {
  font-size: 20px;
  margin-top: 2px;
  font-weight: 700;
  color: dimgray;
}

.dashboardRightDivCard::-webkit-scrollbar {
  width: 8px;
}

.dashboardRightDivCard::-webkit-scrollbar-thumb {
  background-color: #333333;
  border-radius: 4px;
}

.dashboardRightDivCard {
  word-break: break-word;
  background-color: white;
  border-radius: 20px;
  padding: 15px;
  box-sizing: border-box;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
}

.dashboardPreviousEarningsDiv {
  word-break: break-word;
  background-color: white;
  border-radius: 20px;
  padding: 15px;
  box-sizing: border-box;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
}

.infoCard {
  background-color: white;
  width: 100%;
  border-radius: 20px;
  overflow: hidden;
}

.shopInfoImageDiv {
  background-image: url(https://images.unsplash.com/photo-1528698827591-e19ccd7bc23d?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1476&q=80);
  background-size: 100%;
  width: 100%;
  aspect-ratio: 16/9;
  position: relative;
}

.shopImageOverlayDiv {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  gap: 10px;
  align-items: flex-end;
  padding-bottom: 10px;
  box-sizing: border-box;
  transition: background-color 200ms ease-in;
}

.infoCard:hover .shopImageOverlayDiv {
  background-color: rgba(0, 0, 0, 0);
}

.shopRatingDiv {
  width: fit-content;
  height: fit-content;
  padding: 3px 5px;
  margin-left: 10px;
  background-color: #b90504;
  color: white;
  font-family: Montserrat;
  font-weight: bolder;
  border-radius: 5px;
  font-size: 18px;
}

.shopImageOverlayDiv {
  font-size: 10px;
  color: white;
  font-family: Montserrat;
  margin: 0px 0px;
}

.shopInfoTextDiv {
  font-family: Montserrat;
  padding: 20px 15px;
  font-size: 15px;
  width: 100%;
  color: #222222;
  box-sizing: border-box;
}

.shopInfoTextDiv h1 {
  font-size: 20px;
}

.shopInfoTextDiv h2 {
  color: #333333;
  margin-top: 15px;
  font-size: 18px;
  font-weight: 600;
}

#shopInfoImageUpdate {
  display: none;
}

@media (max-width: 780px) {
  .infoCardParentDiv {
    width: 100%;
  }
}
