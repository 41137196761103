.landingPageBody {
  width: 100%;
  min-height: 100vh;
  background: #b90504;
  transition: background 500ms ease-in;
}

.landingPageNavBarDiv {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 90px;
  background: #b90504;
  transition: top 150ms ease-in, background 400ms ease-in;
  color: white;
  padding: 0px 40px;
  box-sizing: border-box;
  opacity: 0.98;
  z-index: 5;
}

.landingPageNavBarDiv h1 {
  font-family: Montserrat;
  font-size: 50px;
  font-weight: 700;
}

.landingPageNavBarLinksDiv {
  margin-left: auto;
  gap: 40px;
  font-family: Montserrat;
}

.landingPageNavBarLinksDiv a {
  color: white;
  text-decoration: none;
  font-size: 20px;
}

#homeSection {
  width: 100%;
  height: 100vh;
}

#detailsSection {
  width: 100%;
}

#footer {
  width: 100%;
  height: 250px;
  background-color: #b90504;
  margin-top: 40px;
  gap: 50px;
}

.homeSectionContentContainer {
  gap: 0px;
  padding-right: 50px;
}

.homeSectionTextDiv {
  color: white;
  font-family: Noto Sans;
  width: 700px;
  gap: 10px;
}

.homeSectionTextDiv h1 {
  font-size: 60px;
  font-weight: 700;
  line-height: 65px;
}

.homeSectionTextDiv p {
  font-family: Roboto;
  font-size: 25px;
  font-weight: 300;
}

.homeSectionTextDiv a {
  height: 40px;
  background: transparent;
  border: 2px solid white;
  font-family: Roboto;
  color: white;
  font-size: 20px;
  border-radius: 20px;
  width: fit-content;
  padding: 0px 20px;
  margin-top: 30px;
  cursor: pointer;
  transition: all 200ms ease-in;
}

.homeSectionTextDiv a:hover {
  background: white;
  color: #b90504;
}

.homeSectionImageDiv {
  width: 500px;
  flex-shrink: 0;
}

.homeSectionImageDiv img {
  width: 100%;
  aspect-ratio: 1/1;
  transform: rotate(-23.53deg) translateY(-20px);
}

.landingPageHowItWorksDiv {
  gap: 40px;
  margin-top: 50px;
  font-family: Montserrat;
  color: white;
}

.landingPageHowItWorksCardDiv {
  gap: 30px;
}

.landingPageHowItWorksCard {
  width: 300px;
  height: 250px;
  border-radius: 20px;
  border: 4px solid #b90504;
  gap: 10px;
  padding: 0px 30px;
  box-sizing: border-box;
  text-align: center;
  opacity: 0;
  transition: opacity 500ms ease-in;
}

.landingPageHowItWorksCard h2 {
  font-size: 28px;
}

.landingPageHowItWorksCard p {
  font-size: 22px;
}

.landingPageAppear {
  opacity: 1;
}

.landingPageShopSwiper {
  width: 100%;
}

.landingPageShopCard {
  position: relative;
  width: 100%;
  aspect-ratio: 16/9;
  background-size: 100%;
  border-radius: 40px;
  margin: 40px 0px;
  overflow: hidden;
}

.landingPageShopOverlay {
  width: 100%;
  height: 100%;
  background-color: rgb(0, 0, 0, 0.6);
  color: white;
  font-family: Montserrat;
  text-align: center;
  padding: 0px 100px;
  box-sizing: border-box;
}

.landingPageShopOverlay h1 {
  font-size: 30px;
}

.landingPageShopOverlay p {
  font-size: 20px;
  margin-top: 40px;
}

.landingPageShopOverlay h3 {
  font-size: 20px;
}

.landingPageShopRating {
  width: fit-content;
  height: fit-content;
  padding: 3px 5px;
  font-weight: bolder;
  border-radius: 5px;
  font-size: 15px;
}

.landingPageFooterLogoImageDiv {
  margin: auto 40px auto 60px;
}

.landingPageFooterLogoImageDiv img {
  transform: rotate(-23.53deg);
  height: 100px;
  width: 100px;
}

.landingPageFooterColumn {
  height: 100%;
  padding-top: 40px;
  box-sizing: border-box;
  color: white;
  font-family: Montserrat;
  gap: 20px;
}

.landingPageFooterColumn a {
  font-size: 20px;
  font-weight: 500;
  text-decoration: none;
  color: white;
}

.landingPageFooterLinkSVG {
  height: 30px;
  width: 30px;
  fill: white;
}

.landingPageFooterSupportDiv {
  margin: auto 100px auto auto;
  color: white;
  font-family: Montserrat;
  width: 250px;
}

.landingPageFooterSupportDiv h2 {
  padding: 10px 0px;
  border-bottom: 2px solid white;
  text-align: center;
  width: 200px;
}

.landingPageFooterSupportDiv p {
  text-align: center;
  margin: 20px 0px;
}

.landingPageFooterSupportDiv span {
  font-weight: 600;
}

.landingPageMobileMenu {
  position: fixed;
  right: 0;
  min-width: 280px;
  height: 100%;
  background-color: #191919;
  color: white;
  animation-name: menuSlideInRight;
  animation-duration: 500ms;
}

.landingPageMobileMenuTopBar {
  background-color: #b90504;
  font-family: Spartan;
  height: 45px;
  font-size: 28px;
  font-weight: 800;
}

.landingPageMobileMenuOptions {
  background-color: #222222;
  padding: 15px 0 15px 20px;
  margin-top: 10px;
  box-sizing: border-box;
  font-size: 25px;
  font-family: Montserrat;
  font-weight: 500;
  color: white;
}

@keyframes menuSlideInRight {
  from {
    right: -280px;
  }
  to {
    right: 0;
  }
}

@keyframes menuSlideOutRight {
  from {
    right: 0px;
  }
  to {
    right: -280px;
  }
}

@media (max-width: 1325px) {
  .homeSectionImageDiv {
    width: 350px;
  }

  .homeSectionTextDiv {
    width: 550px;
  }

  .landingPageFooterSupportDiv {
    margin-right: 20px;
  }
}

@media (max-width: 780px) {
  #homeSection {
    height: fit-content;
    padding: 100px 0px;
  }

  .landingPageNavBarDiv {
    padding: 0px 20px;
    height: 70px;
  }

  .landingPageNavBarDiv h1 {
    font-size: 40px;
  }

  .landingPageNavBarLinksDiv {
    display: none;
  }

  .homeSectionContentContainer {
    flex-direction: column;
    padding: 0;
    gap: 50px;
  }

  .homeSectionTextDiv {
    width: 100%;
    align-items: center;
    text-align: center;
  }

  .homeSectionImageDiv {
    width: 100%;
  }

  .landingPageHowItWorksCardDiv {
    flex-direction: column;
  }

  .landingPageShopCard {
    border-radius: 20px;
  }

  .landingPageFooterSupportDiv {
    margin-right: 20px;
  }
}

@media (max-width: 480px) {
  .landingPageNavBarDiv {
    padding: 0px 15px;
  }

  .homeSectionTextDiv h1 {
    font-size: 40px;
    line-height: 45px;
  }

  .homeSectionTextDiv a {
    margin-top: 15px;
  }

  .homeSectionImageDiv img {
    width: 80%;
  }

  #footer {
    flex-direction: column;
    height: fit-content;
    align-items: center;
    gap: 40px;
  }

  .landingPageFooterColumn {
    padding: 0;
  }

  .landingPageFooterLogoImageDiv {
    margin: 20px auto 0 auto;
  }

  .landingPageFooterSupportDiv {
    margin: 0px auto;
  }

  .landingPageFooterSupportDiv div {
    width: 100%;
  }

  .landingPageShopCard {
    border-radius: 15px;
  }

  .landingPageShopOverlay {
    padding: 0px 20px;
  }

  .landingPageShopOverlay h1 {
    font-size: 22px;
  }

  .landingPageShopOverlay p {
    font-size: 16px;
  }
}
