#addProductDiv {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 450px;
  min-width: 400px;
  width: 25%;
  border-radius: 20px;
  background-color: #333333;
  display: flex;
  justify-content: center;
  align-items: center;
}

#addProductForm {
  position: absolute;
  top: 60px;
  display: flex;
  flex-direction: column;
  width: 95%;
  gap: 15px;
  justify-content: center;
  align-items: center;
  height: calc(100% - 60px);
}

#addProductForm h1 {
  color: white;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 500;
  margin-left: 5px;
}

#addProductForm h2 {
  color: white;
  font-family: Montserrat;
  font-size: 10px;
  font-weight: 500;
  margin-left: 5px;
}

#addProductForm input {
  width: 100%;
  height: 44px;
  border-radius: 10px;
  font-family: Montserrat;
  font-weight: bold;
  padding-left: 15px;
  font-size: 20px;
  box-sizing: border-box;
}

.addProductSearchButton {
  position: absolute;
  top: 68px;
  right: 3px;
  border-radius: 7px;
  height: 35px;
  width: 35px;
  background-color: #b90504;
}

.addProductSearchButton img {
  height: 30px;
  width: 30px;
}

#isAvailableButton {
  background-color: green;
  border: none;
  height: 40px;
  border-radius: 20px;
  width: 60%;
  color: white;
  font-family: Montserrat;
  font-weight: bold;
  cursor: pointer;
}

#addProductSubmitButton:hover {
  background-color: #990100;
}

.addProductSearchInputDiv {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -180px);
  z-index: 2;
  width: 90%;
}

.inventoryAddProductSearchSuggestions {
  height: 250px;
  width: calc(95% + 15px);
  background-color: white;
  border-radius: 0px 0px 20px 20px;
  overflow-y: scroll;
}

.inventoryAddProductSearchSuggestions::-webkit-scrollbar {
  width: 8px;
}

.inventoryAddProductSearchSuggestions::-webkit-scrollbar-thumb {
  background-color: #333333;
  border-radius: 4px;
}

.inventoryAddProductSuggestionCard {
  color: #333333;
  padding: 10px;
  font-family: Montserrat;
  font-weight: 500;
  font-size: 12px;
  cursor: pointer;
  border-bottom: 2px solid #e8e8e8;
}

.inventoryAddProductSuggestionCard:hover {
  background-color: #e6e6e6;
}

@media (max-width: 480px) {
  #addProductDiv {
    width: 95%;
    min-width: unset;
  }

  .inventoryAddProductSearchSuggestions {
    width: calc(95% + 15px);
  }
}
